import selectAll from './select-all'
// import baguetteBox from 'baguettebox.js'
import xhr from 'xhr'
// import Swiper from 'swiper/dist/js/swiper'
import baguetteBox from 'baguettebox.js'
import $ from 'jquery'
import init from './verleih'

function closeModal () {
  let m = document.querySelectorAll('#item-modal.open')
  m.forEach(function (value, i) {
    value.classList.remove('open')
  })
}

function clickOutSide (e) {
  // .full-image == baguette gallery image
  if ($(e.target).closest('.modal-content').length === 0 && $(e.target).closest('.full-image').length === 0) {
    closeModal()
  }
}

function addItems () {
  let artID = this.getAttribute('data-id')
  let artCount = $('#artAmount').val()

  // accessories
  var c = document.querySelectorAll('input[id^="accCheck"]')
  let accArr = []
  c.forEach(function (input, i) {
    if (input.checked) {
      accArr[input.value] = document.getElementById('accAmount-' + input.value).value
    }
  })
  let data = {
    artID: artID,
    amount: artCount,
    modus: 'addItem',
    modulPfad: 'api/plugins/verleih/'
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      return false
    }
    let item = JSON.parse(body)
    if (item.body) {
      document.getElementById('detail--list').innerHTML = item.body
      init()
      modal()
    }
  })

  accArr.forEach(function (accAmount, accId) {
    let data = {
      artID: accId,
      amount: accAmount,
      modus: 'addItem',
      modulPfad: 'api/plugins/verleih/'
    }
    xhr({
      body: JSON.stringify(data),
      uri: 'ajax.php',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }, function (err, resp, body) {
      if (err) {
        return false
      }
      let item = JSON.parse(body)
      if (item.body) {
        document.getElementById('detail--list').innerHTML = item.body
        init()
        modal()
      }
    })
  })
  closeModal()
}

function getItemInfo (artID, element) {
  let data = {
    artID: artID,
    modus: 'getItemInfo',
    modulPfad: 'api/plugins/verleih/'
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      return false
    }
    let item = JSON.parse(body)
    if (item.body) {
      document.getElementById('item-modal-body').innerHTML = item.body
      modal(element)
      // eslint-disable-next-line
      new Swiper('#modal-slider', {
        effect: 'slide',
        slidesPerView: 1,
        autoplay: 6000,
        speed: 2000,
        loop: true
      })

      baguetteBox.run('.acc-gallery')
      document.getElementById('modal-add-item-btn').addEventListener('click', addItems)
    }
  })
}

export function modal (elem) {
  let closeButton = selectAll('.close')
  if (typeof elem !== 'undefined') {
    elem.classList.add('open')

    // slider with thumbs only in swiper version > 4.5
    // eslint-disable-next-line
    var galleryThumbs = new Swiper('.listing-modal__gallery-thumbs', {
      spaceBetween: 10,
      slidesPerView: 5,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true
    })
    // eslint-disable-next-line
    var galleryTop = new Swiper('.listing-modal__gallery-top', {
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      thumbs: {
        swiper: galleryThumbs
      }
    })

    baguetteBox.run('.article-gallery')
  }

  document.body.classList.add('stop-scrolling')
  if (closeButton !== null) {
    for (var i = closeButton.length - 1; i >= 0; i--) {
      closeButton[i].removeEventListener('click', function () {
      })
      closeButton[i].addEventListener('click', function () {
        elem.classList.remove('open')
        document.body.classList.remove('stop-scrolling')
        if (this.classList.contains('reload')) {
          window.location.reload()
        }
      }, false)
    }
  }

  $(document).off('click', clickOutSide)
  $(document).on('click', clickOutSide)
}

export default function initModal () {
  let modals = selectAll('.modal-open')
  if (modals !== null) {
    for (var i = modals.length - 1; i >= 0; i--) {
      modals[i].removeEventListener('click', function () {
      })
      modals[i].addEventListener('click', function (event) {
        event.stopPropagation()
        event.preventDefault()
        let element = document.getElementById(this.getAttribute('data-target'))
        if (this.classList.contains('modal-body')) {
          getItemInfo(this.getAttribute('data-id'), element)
        } else if (this.classList.contains('modal-anfrage')) {
          document.getElementById('anfrage-titel').value = this.getAttribute('data-titel')
          document.getElementById('anfrage-artNr').value = this.getAttribute('data-number')
          modal(element)
        } else {
          modal(element)
        }
        return false
      }, {passive: false})
    }
  }
  let modalsGallery = selectAll('.open-gallery')
  if (modalsGallery !== null) {
    for (var g = modalsGallery.length - 1; g >= 0; g--) {
      modalsGallery[g].removeEventListener('click', function () {
      })
      modalsGallery[g].addEventListener('click', function (event) {
        event.stopPropagation()
        event.preventDefault()
        xhr({
          body: JSON.stringify({
            modus: 'loadImageGalary',
            carID: this.getAttribute('data-id')
          }),
          uri: 'ajax.php',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
          }
        }, (err, res, body) => {
          if (err) {
            console.log(err)
            return false
          } else {
            let data = JSON.parse(body)
            if (data.status === 'success') {
              let element = document.getElementById('modal-gallery')
              document.getElementById('modal-body-gallery').innerHTML = data.html
              modal(element)
              // eslint-disable-next-line
              new Swiper('.gallery-top', {
                loop: true,
                nextButton: '.gal-next',
                prevButton: '.gal-prev'
              })
            }
          }
        })
        return false
      }, {passive: false})
    }
  }
}
