/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import baguetteBox from 'baguettebox.js'
import categoryInit from './modules/kacheln'
import initModal from './modules/modal'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import initToggle from './modules/toggle-container'
import mobileNavigation from './modules/mobile-navigation'
import initItem from './modules/item'
// import Swiper from 'swiper/dist/js/swiper'
import notifier from './vendor/notifier'
import debug from './modules/debug'
import initVerleih from './modules/verleih.js'
import initToggleRecaptcha from './modules/toggleReCaptcha.js'
import $ from 'jquery'

let dateStartTo = Date.now()

/**
 * Ab geht die Reise
 */
domready(() => {
  if (document.getElementById('message-notifer')) {
    console.log(document.getElementById('message-notifer'))
    notifier.show(document.getElementById('message-notifer').getAttribute('data-message'), '', document.getElementById('message-notifer').getAttribute('data-status'), '', 4000)
  }
  mobileNavigation()
  initItem()
  categoryInit()
  initModal()
  formSubmit()
  initMaps()
  initToggle()
  debug()
  initVerleih()
  initToggleRecaptcha()
  // eslint-disable-next-line
  new Swiper('#mmeinungen', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: 6000,
    speed: 2000,
    loop: true,
    pagination: '.meinunge-page'
  })

  // eslint-disable-next-line
  new Swiper('#vn-bilder', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: 6000,
    speed: 2000,
    loop: true,
    pagination: '.vn-bilder-page'
  })

  // eslint-disable-next-line
  new Swiper('.slider-js', {
    effect: 'fade',
    slidesPerView: 1,
    autoplay: {
      delay: 6000
    },
    speed: 2000,
    paginationClickable: true,
    loop: true
  })

// eslint-disable-next-line
  new Swiper('.leistungen-slider', {
    slidesPerView: 3,
    slidesPerColumn: 1,
    spaceBetween: 0,
    speed: 6000,
    nextButton: '.leistung-button-next',
    prevButton: '.leistung-button-prev',
    loop: true,
    breakpoints: {
      568: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  })

// eslint-disable-next-line
  new Swiper('.image-slider-container', {
    pagination: {
      el: '.image-slider-page'
    },
    autoplay: {
      delay: 3000
    },
    speed: 2000,
    loop: true,
    centeredSlides: true
  })

  // var swiper = new Swiper('.image-slider-container', {
  //   pagination: {
  //     el: '.image-slider-page',
  //   },
  //   autoplay: {
  //     delay: 3000,
  //   },
  //   speed: 2000,
  //   loop:true
  // });

  // eslint-disable-next-line
  baguetteBox.run('.gallery')
// eslint-disable-next-line
  let dateInputFrom = tail.DateTime('.tail-datetime-field-from', {
    animate: true,
    classNames: false,
    closeButton: true,              // New in 0.4.5
    dateFormat: 'dd.mm.YYYY',
    dateStart: Date.now(),
    dateRanges: [],
    dateBlacklist: true,
    dateEnd: false,
    locale: 'de',
    position: 'bottom',
    rtl: 'auto',
    startOpen: false,
    stayOpen: false,
    timeFormat: '',
    // timeFormat: 'HH:ii:ss',
    timeHours: null,                // New Syntax in 0.4.5
    timeMinutes: null,              // New Syntax in 0.4.5
    timeSeconds: 0,                 // New Syntax in 0.4.5
    timeIncrement: true,            // New in 0.4.5
    timeStepHours: 1,               // New in 0.4.3
    timeStepMinutes: 5,             // New in 0.4.3
    timeStepSeconds: 5,             // New in 0.4.3
    today: true,
    tooltips: [],
    viewDefault: 'days',
    viewDecades: true,
    viewYears: true,
    viewMonths: true,
    viewDays: true,
    weekStart: 1
  })

  // eslint-disable-next-line
  let dateInputTo = tail.DateTime('.tail-datetime-field-to', {
    animate: true,
    classNames: false,
    closeButton: true,              // New in 0.4.5
    dateFormat: 'dd.mm.YYYY',
    dateStart: dateStartTo,
    dateRanges: [],
    dateBlacklist: true,
    dateEnd: false,
    locale: 'de',
    position: 'bottom',
    rtl: 'auto',
    startOpen: false,
    stayOpen: false,
    timeFormat: '',
    // timeFormat: 'HH:ii:ss',
    timeHours: null,                // New Syntax in 0.4.5
    timeMinutes: null,              // New Syntax in 0.4.5
    timeSeconds: 0,                 // New Syntax in 0.4.5
    timeIncrement: true,            // New in 0.4.5
    timeStepHours: 1,               // New in 0.4.3
    timeStepMinutes: 5,             // New in 0.4.3
    timeStepSeconds: 5,             // New in 0.4.3
    today: true,
    tooltips: [],
    viewDefault: 'days',
    viewDecades: true,
    viewYears: true,
    viewMonths: true,
    viewDays: true,
    weekStart: 1
  })

  dateInputFrom.on('change', function (e) {
    $('.tail-datetime-field-to').val('')
    let sDate = $('.tail-datetime-field-from').val().split('.')
    let d = sDate[0]
    let m = sDate[1] - 1
    let y = sDate[2]

    dateInputTo.config('dateStart', new Date(y, m, d), false)
  })
})
