import 'core-js/fn/object/assign'
import domready from 'domready'
import xhr from 'xhr'
import selectAll from './select-all'
import modal from './modal'
import $ from 'jquery'

let counter = 0

function addItem () {
  let artID = this.getAttribute('data-id')
  let data = {
    artID: artID,
    modus: 'addItem',
    modulPfad: 'api/plugins/verleih/'
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      return false
    }
    let item = JSON.parse(body)
    if (item.body) {
      document.getElementById('detail--list').innerHTML = item.body
      init()
      modal()
    }
  })
}

function changeItemNumberWK () {
  let data = {
    mlID: this.getAttribute('data-id'),
    artID: this.getAttribute('data-artID'),
    count: this.value,
    modus: 'changeItemNumberWK',
    modulPfad: 'api/plugins/verleih/'
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      return false
    }
    let item = JSON.parse(body)
    if (item.body) {
      document.getElementById('detail--list').innerHTML = item.body
      init()
      modal()
    }
  })
}

function changeItemNumber () {
  let data = {
    mlID: this.getAttribute('data-id'),
    artID: this.getAttribute('data-artID'),
    count: this.value,
    modus: 'changeItemNumber',
    modulPfad: 'api/plugins/verleih/'
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      return false
    }
    let item = JSON.parse(body)
    if (item.body) {
      document.getElementById('detail--list').innerHTML = item.body
      init()
      modal()
    }
  })
}

function deleteItem () {
  let data = {
    mlID: this.getAttribute('data-id'),
    modus: 'deleteItem',
    template: this.getAttribute('data-template'),
    modulPfad: 'api/plugins/verleih/'
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      return false
    }
    let item = JSON.parse(body)
    if (item.body) {
      document.getElementById('detail--list').innerHTML = item.body
      init()
      modal()
    }
  })
}

function searchItems () {
  let data = {
    catID: document.getElementById('filter--category').value,
    freeSearch: document.getElementById('filter--search').value,
    modus: 'searchItems',
    modulPfad: 'api/plugins/verleih/'
  }
  xhr({
    body: JSON.stringify(data),
    uri: 'ajax.php',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }, function (err, resp, body) {
    if (err) {
      return false
    }
    let item = JSON.parse(body)
    document.getElementById('listing--items').innerHTML = item.body
    init()
    modal()
  })
}

function getUrlParameters () {
  let res = []
  let url = window.location.search.substring(1)
  let paramsTmp = url.split('&')
  for (let i = 0; i < paramsTmp.length; i++) {
    let paramArr = paramsTmp[i].split('=')
    res[paramArr[0]] = paramArr[1]
  }
  return res
}

export default function init () {
  domready(() => {
    let productAdd = selectAll('.product-add')
    if (productAdd !== null) {
      for (let i = productAdd.length - 1; i >= 0; i--) {
        productAdd[i].removeEventListener('click', addItem)
        productAdd[i].addEventListener('click', addItem, {passive: false})
      }
    }

    let productDelete = selectAll('.item-delete')
    if (productDelete !== null) {
      for (let i = productDelete.length - 1; i >= 0; i--) {
        productDelete[i].removeEventListener('click', deleteItem)
        productDelete[i].addEventListener('click', deleteItem, {passive: false})
      }
    }

    let productCount = selectAll('.detail-count-item')
    if (productCount !== null) {
      for (let i = productCount.length - 1; i >= 0; i--) {
        productCount[i].removeEventListener('change', changeItemNumber)
        productCount[i].removeEventListener('blur', changeItemNumber)
        productCount[i].addEventListener('change', changeItemNumber, {passive: false})
        productCount[i].addEventListener('blur', changeItemNumber, {passive: false})
      }
    }
    let productCountWK = selectAll('.detail-count-item-wk')
    if (productCountWK !== null) {
      for (let i = productCountWK.length - 1; i >= 0; i--) {
        productCountWK[i].removeEventListener('change', changeItemNumberWK)
        productCountWK[i].removeEventListener('blur', changeItemNumberWK)
        productCountWK[i].addEventListener('change', changeItemNumberWK, {passive: false})
        productCountWK[i].addEventListener('blur', changeItemNumberWK, {passive: false})
      }
    }
    if (document.getElementById('filter--category') !== null) {
      document.getElementById('filter--category').removeEventListener('change', searchItems)
      document.getElementById('filter--category').addEventListener('change', searchItems, {passive: false})

      document.getElementById('filter--search').removeEventListener('keyup', searchItems)
      document.getElementById('filter--search').addEventListener('keyup', searchItems, {passive: false})
    }

    if (getUrlParameters() != null && getUrlParameters() !== 'undefined' && counter === 0) {
      let cat = getUrlParameters()['q']
      if (cat) {
        // set filter
        $('#filter--category').val(cat)
        // execute filter
        searchItems()
        counter = 1
      }
    }
  })
}
